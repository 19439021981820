.title {
  font-family: Georgia, 'Times New Roman', Times, serif;
  text-align: center;
  font-size: 3rem;
}

th {
  text-align: left;
  font-size: x-large;
}

input {
  width: 100%;
  font-size: x-large;
  padding: 0.2rem;
}